import React, { useCallback, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../contexts/UserContext";
import { PathName } from "../../constants/PathName";
import { calculate_top_margin, in_array, setPageTitle } from "../../utils/utils";
import useProduct from "../../hooks/useProduct";
import Loading from "../../components/Loading/Loading";
import { Link, useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { infoMenuListAtom } from "../../contexts/infoMenuList.recoil";
import MenuList from "./MenuList";
import { topPdfViewerUrlAtom } from "../../contexts/topPdfViewer.recoil";
import ParseHTMLContent from "../../components/ParseHTMLContent/ParseHTMLContent";
import {
	HiChevronDoubleRight,
	HiChevronLeft,
	HiChevronRight,
	HiOutlineChevronDoubleLeft,
} from "react-icons/hi";
import { MdOutlineRestartAlt } from "react-icons/md";
import classNames from "classnames";
import { useWindowBreakpoints } from "../../hooks/useWindowBreakpoints";
import { NotAccessible } from "../../components/NotAccessible/NotAccessible";
import { useInfoNavigationLinks, useInfoQueryParams, UseInfoService } from "./Info.service";
import { ProductInfoSectionFragment } from "../../generated/graphql";

const Info = () => {
	const { pages, navigation, error, loading } = UseInfoService();
	const { msub, module, navSection, page: inner_p } = useInfoQueryParams();

	const history = useHistory();
	const { store } = useContext(UserContext);
	const { translation, permissionGroup, userLanguage, userData } = store;
	const product = useProduct();
	const mainRef = useRef<HTMLDivElement>(null);
	const setPDFUrl = useSetRecoilState(topPdfViewerUrlAtom);

	const setInfoMenuList = useSetRecoilState(infoMenuListAtom);
	const { nestedSectionsArray, infoPageLinks } = useInfoNavigationLinks(
		navigation,
		pages,
		inner_p ?? "",
	);
	const { isWindowMobile } = useWindowBreakpoints();

	//@TODO unverified variables
	// const $userTasks = "";
	// const $task_completed = "";
	// const db_get_task_status = () => {};

	//local functions
	const setInitialUrl = useCallback(
		(nestedSections) => {
			if (nestedSections[0].pages.length > 0) {
				let firstProductInfo = nestedSections[0].pages[0];
				history.push(
					PathName.DASHBOARD +
						product +
						"/?m=" +
						module +
						"&msub=" +
						msub +
						"&s=" +
						firstProductInfo.sectionId +
						"&p=" +
						firstProductInfo.pageId +
						"&l=" +
						userLanguage,
				);
			}
		},
		[history, module, msub, product, userLanguage],
	);

	useEffect(() => {
		setPageTitle(product);
		return () => {
			// clean up, remove recoil from sidebar
			setInfoMenuList(undefined);
			setPDFUrl(undefined);
		};
	}, [product, setInfoMenuList, setPDFUrl]);

	useEffect(() => {
		if (pages && navigation) {
			setInfoMenuList(<MenuList navigation={navigation} pages={pages} isInAppSwitcher={true} />);
		}
	}, [pages, navigation, setInfoMenuList]);

	useEffect(() => {
		if (nestedSectionsArray.length > 0 && pages.length > 0 && (!navSection || !inner_p)) {
			setInitialUrl(nestedSectionsArray);
		}
	}, [navSection, inner_p, pages, nestedSectionsArray, setInitialUrl]);

	useEffect(() => {
		if (mainRef.current) {
			mainRef.current.scrollTop = 0;
		}
	}, [inner_p]);

	const permissionGroupTest = (section: ProductInfoSectionFragment) => {
		if (
			(in_array(permissionGroup.companyType, section.permissionGroupFields?.acceptcompanytype) &&
				(in_array(permissionGroup.accessLevel, section.permissionGroupFields?.acceptaccessroles) ||
					in_array(
						permissionGroup.customRole,
						section.permissionGroupFields?.acceptcustomroles,
					))) ||
			in_array(userData.eID, section.permissionGroupFields?.userEids)
		) {
			return true;
		}
		return false;
	};

	if (loading) return <Loading />;
	if (error) return <NotAccessible />;

	if (!pages || !navigation || !navSection || !inner_p) {
		return <NotAccessible />;
	}

	return (
		<div
			id="main"
			className="container-fluid main"
			style={{ ...calculate_top_margin(product, module) }}
			ref={mainRef}
		>
			<div className="row">
				<div
					className="hidden-xs hidden-sm hidden-md col-lg-3 sidebar"
					style={{ top: module === "eeotf" ? "100px" : "100px" }}
				>
					<MenuList navigation={navigation} pages={pages} isInAppSwitcher={false} />
				</div>
				<div className="col-xs-12 col-lg-9 col-lg-offset-3 last info-sections">
					{pages.map((resPage, key) => {
						return (
							<React.Fragment key={key}>
								{(() => {
									const page = resPage.productInfoFields;
									if (inner_p === page!.pifid) {
										const navItem = navigation.filter((el) => {
											return el.productInfoNavigationFields?.navId === page?.parent;
										});
										const customColor = navItem[0]?.productInfoNavigationFields?.customColor;

										const pageTitle = translation(page?.title, page?.titleEs);
										return (
											<>
												{pageTitle != null && pageTitle !== "" && (
													<div className="row ">
														<div className="col-xs-12">
															<h2
																className="page-header"
																style={{
																	color:
																		customColor != null &&
																		customColor !== "" &&
																		customColor !== "#333333"
																			? customColor
																			: "#bf0c0c",
																}}
															>
																<ParseHTMLContent htmlString={pageTitle} />
															</h2>
														</div>
													</div>
												)}

												<div className="sections-container">
													{page?.content &&
														page.content.map((inSection, key) => {
															const section = inSection?.section;
															const sectionFields = inSection?.section?.productInfoSectionFields;
															if (section && permissionGroupTest(section) === false) {
																return <React.Fragment key={key} />;
															}
															const sectionTitle = translation(
																sectionFields?.title,
																sectionFields?.titleEs,
															);
															return (
																<React.Fragment key={key}>
																	<section className={"row "}>
																		{sectionTitle != null && sectionTitle !== "" && (
																			<div className="col-xs-12">
																				<h3>
																					<ParseHTMLContent htmlString={sectionTitle} />
																				</h3>
																			</div>
																		)}

																		<ParseHTMLContent
																			htmlString={translation(
																				sectionFields?.content,
																				sectionFields?.contentEs,
																			)}
																		/>
																	</section>
																</React.Fragment>
															);
														})}
												</div>
											</>
										);
									}
								})()}
							</React.Fragment>
						);
					})}

					<div className="row">
						<div className="col-xs-12 text-center">
							<div className="list-inline">
								{/* @TODO NEVER WORKED ON */}
								{/* {(() => {
									//only show task buttons if this page is a task
									// @TODO USER TASK PROGRESS
									if (is_array($userTasks[inner_p])) {
										// check if is this task completed
										db_get_task_status(inner_p, "no");

										if ($task_completed !== "completed") {
											//@TODO verify if filtered
											//if (!in_array($_SESSION['sso_accessLevel'], $userTasks[inner_p]['deniedAccessRoles'])) { //only show to roles assigned this task
											if (isset(ref)) {
												const $urlParts = parse_url(ref);
												if (isset(errors) === "true") {
													print_r($urlParts);
												}
												return (
													<div>
														<Link
															className="btn btn-primary"
															to={
																$urlParts["scheme"] +
																"://" +
																$urlParts["host"] +
																$urlParts["path"] +
																"?m=" +
																module +
																"&msub=" +
																msub +
																"&task_id=" +
																inner_p +
																"&task_status=done&l=" +
																userLanguage
															}
														>
															{translation(
																"Mark This Section As Read",
																"Marca esta sección como completada",
															)}
														</Link>
													</div>
												);
											} else {
												return (
													<div>
														<Link
															className="btn btn-primary"
															to={
																PathName.DASHBOARD +
																"mcd-paths/?m=" +
																module +
																"&msub=" +
																msub +
																"&task_id=" +
																inner_p +
																"&task_status=done&l=" +
																userLanguage
															}
														>
															{translation(
																"Mark This Section As Read",
																"Marca esta sección como completada",
															)}
														</Link>
													</div>
												);
											}
											//}
										}
									}
								})()} */}

								<div className="info-page-links">
									{infoPageLinks.prevSectionFirstPage && (
										<Link
											className={classNames("btn btn-secondary info-page-link", {
												"order-3": isWindowMobile,
											})}
											to={
												PathName.DASHBOARD +
												product +
												"/?m=" +
												module +
												"&msub=" +
												msub +
												"&s=" +
												infoPageLinks.prevSectionFirstPage.sectionId +
												"&p=" +
												infoPageLinks.prevSectionFirstPage.pageId +
												"&l=" +
												userLanguage
											}
										>
											<HiOutlineChevronDoubleLeft />
											{translation("Previous Section", "Sección Anterior")}
										</Link>
									)}
									{infoPageLinks.prevPage && (
										<Link
											className={classNames("btn btn-secondary info-page-link", {
												"order-1": isWindowMobile,
											})}
											to={
												PathName.DASHBOARD +
												product +
												"/?m=" +
												module +
												"&msub=" +
												msub +
												"&s=" +
												infoPageLinks.prevPage.sectionId +
												"&p=" +
												infoPageLinks.prevPage.pageId +
												"&l=" +
												userLanguage
											}
										>
											<HiChevronLeft />
											{translation("Previous Page", "Pagina Anterior")}
										</Link>
									)}
									{infoPageLinks.nextPage && (
										<Link
											className={classNames("btn btn-secondary info-page-link", {
												"order-2": isWindowMobile,
											})}
											to={
												PathName.DASHBOARD +
												product +
												"/?m=" +
												module +
												"&msub=" +
												msub +
												"&s=" +
												infoPageLinks.nextPage.sectionId +
												"&p=" +
												infoPageLinks.nextPage.pageId +
												"&l=" +
												userLanguage
											}
										>
											{translation("Next Page", "Siguiente Pagina")}
											<HiChevronRight />
										</Link>
									)}
									{infoPageLinks.nextSectionFirstPage && (
										<Link
											className={classNames("btn btn-secondary info-page-link", {
												"order-4": isWindowMobile,
											})}
											to={
												PathName.DASHBOARD +
												product +
												"/?m=" +
												module +
												"&msub=" +
												msub +
												"&s=" +
												infoPageLinks.nextSectionFirstPage.sectionId +
												"&p=" +
												infoPageLinks.nextSectionFirstPage.pageId +
												"&l=" +
												userLanguage
											}
										>
											{translation("Next Section", "Siguiente Sección")}
											<HiChevronDoubleRight />
										</Link>
									)}
									{infoPageLinks.backToStart && (
										<Link
											className={classNames("btn btn-secondary info-page-link", {
												"order-4": isWindowMobile,
											})}
											to={
												PathName.DASHBOARD +
												product +
												"/?m=" +
												module +
												"&msub=" +
												msub +
												"&s=" +
												infoPageLinks.backToStart.sectionId +
												"&p=" +
												infoPageLinks.backToStart.pageId +
												"&l=" +
												userLanguage
											}
										>
											{translation("Back to start", "Volver a inicio")}
											<MdOutlineRestartAlt />
										</Link>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Info;
