import React, { useCallback, useContext, useState } from "react";
import { ChecklistAssignmentTaskTableRowFragment } from "../../../../../generated/graphql";
import { UserContext, UserContextType } from "../../../../../contexts/UserContext";
import ParseUrlTarget from "../../../../../components/ParseUrlTarget/ParseUrlTarget";
import { formatDueDate } from "../ChecklistTasksTable.service";
import {
	formatDateFromYYYYMMDD,
	formatDateTimeYYYY_MM_DD_HH_MM_SS,
	formatDate_MM_DD_YYYY,
} from "../../../../../utils/utils-typescript";
import "./checklistTaskModal.scss";
import Loading from "../../../../../components/Loading/Loading";
import {
	useFetchChecklistAssignmentTasks,
	useGetChecklistAssignment,
	useUpsertChecklistAssignmentTask,
} from "../../checklistDetail.hooks";
import ParseHTMLContent from "../../../../../components/ParseHTMLContent/ParseHTMLContent";
import { useModals } from "../../../../../components/modal/modals.recoil";

interface Props {
	checklistAssignmentTask: ChecklistAssignmentTaskTableRowFragment;
	date: Date | null;
}

export const ChecklistTaskModal: React.FC<Props> = ({ checklistAssignmentTask, date }) => {
	const [activeTab, setActiveTab] = useState<"details" | "notes">("details");
	const [formNotes, setFormNotes] = useState<string>(checklistAssignmentTask.notes ?? "");
	const { store } = useContext<UserContextType>(UserContext);
	const { translation, personID } = store;

	const { checklistAssignment } = useGetChecklistAssignment();
	const { popModal } = useModals();

	const { upsertChecklistAssignmentTask, isLoading: ucatLoading } =
		useUpsertChecklistAssignmentTask();
	const { fetchChecklistAssignmentTasks, isLoading: fetching } = useFetchChecklistAssignmentTasks();

	const completedAtDate = checklistAssignmentTask.finishedAt;

	const saveChecklistTask = useCallback(
		async (shouldCompleteTask?: boolean) => {
			try {
				let finishedAt = checklistAssignmentTask.finishedAt;
				let finishedBy = checklistAssignmentTask.finishedByPersonId;
				if (shouldCompleteTask === true) {
					finishedAt = formatDateTimeYYYY_MM_DD_HH_MM_SS(new Date());
					finishedBy = personID;
				} else if (shouldCompleteTask === false) {
					finishedAt = null;
					finishedBy = null;
				}

				await upsertChecklistAssignmentTask({
					id: checklistAssignmentTask.checklistAssignmentTaskId,
					taskId: parseInt(checklistAssignmentTask.taskId!),
					finishedAt,
					finishedBy,
					notes: formNotes !== "" ? formNotes : null,
				});
				await fetchChecklistAssignmentTasks();
				popModal();
			} catch (error) {
				console.error(error);
			}
		},
		[
			checklistAssignmentTask.finishedAt,
			checklistAssignmentTask.finishedByPersonId,
			checklistAssignmentTask.checklistAssignmentTaskId,
			checklistAssignmentTask.taskId,
			upsertChecklistAssignmentTask,
			formNotes,
			fetchChecklistAssignmentTasks,
			popModal,
			personID,
		],
	);

	const isLoading = ucatLoading || fetching;

	return (
		<div className="checklistTaskModal">
			<div className="tabs">
				<div
					className={"tab " + (activeTab === "details" ? "active" : "")}
					onClick={() => setActiveTab("details")}
				>
					Details
				</div>
				<div
					className={"tab " + (activeTab === "notes" ? "active" : "")}
					onClick={() => setActiveTab("notes")}
				>
					Notes {formNotes !== "" && <i className="dot"></i>}
				</div>
			</div>
			{isLoading && <Loading />}
			{activeTab === "details" && (
				<div className="details">
					<div className="item">
						<div className="label">Task Name</div>
						<div
							className={"value " + (checklistAssignmentTask?.taskIsCritical ? " critical" : "")}
						>
							<ParseHTMLContent
								htmlString={translation(
									checklistAssignmentTask?.taskTitle,
									checklistAssignmentTask?.taskTitleEs,
								)}
							/>
						</div>
					</div>
					<div className="item">
						<div className="label">Description</div>
						<div className="value">
							<ParseHTMLContent
								htmlString={translation(
									checklistAssignmentTask?.taskDescription,
									checklistAssignmentTask?.taskDescriptionEs,
								)}
							/>
						</div>
					</div>
					<div className="item">
						<div className="label">Additional Resources</div>
						<div className="value">
							<i
								onClick={() => {
									popModal();
								}}
							>
								<ParseUrlTarget url={checklistAssignmentTask?.taskMoreDetailsUrl || ""}>
									{checklistAssignmentTask?.taskMoreDetailsUrl}
								</ParseUrlTarget>
							</i>
						</div>
					</div>
					<div className="item">
						<div className="label">Parent Checklist</div>
						<div className="value blue-title">
							<ParseHTMLContent
								htmlString={translation(checklistAssignment?.title, checklistAssignment?.titleEs)}
							/>
						</div>
					</div>
					<div className="status-area">
						<div className="col">
							<div className="label">Status</div>
							<div className="value">
								{completedAtDate != null ? "Completed Task" : "Current Task"}
							</div>
						</div>
						{date != null && (
							<div className="col">
								<div className="label">Due Date</div>
								<div className="value">
									{(checklistAssignmentTask.taskDaysOrActualDate === "Due Date Days" ||
										checklistAssignmentTask.taskDaysOrActualDate === "due_date_days") &&
										checklistAssignmentTask.taskDueDateDays != null && (
											<div className="checklist-table-element checklist-due-date-container">
												<div className="checklist-circle-container">
													<div
														className="checklist-circle checklist-due-date-container-items"
														style={{
															backgroundColor: formatDueDate(
																date,
																checklistAssignmentTask?.taskDueDateDays!,
																completedAtDate != null,
															).color,
														}}
													></div>
												</div>
												{
													formatDueDate(
														date,
														checklistAssignmentTask?.taskDueDateDays!,
														completedAtDate != null,
													).message
												}
											</div>
										)}
									{checklistAssignmentTask.taskDaysOrActualDate === "actual_due_date" &&
										checklistAssignmentTask.taskActualDueDate != null && (
											<div className="checklist-table-element checklist-actual-due-date-container">
												{
													formatDateFromYYYYMMDD(checklistAssignmentTask.taskActualDueDate)
														.dateString
												}{" "}
												{checklistAssignmentTask.taskActualDueDateTime}{" "}
												{checklistAssignmentTask.taskActualDueDateTime != null &&
													`(${checklistAssignmentTask.taskActualDueDateTimezone})`}
											</div>
										)}
								</div>
							</div>
						)}
						<div className="col">
							<div className="label">Completed On</div>
							<div className="value">
								{completedAtDate != null ? formatDate_MM_DD_YYYY(new Date(completedAtDate)) : ""}
							</div>
						</div>
						<div className="col">
							<div className="label">Completed By</div>
							<div className="value">{checklistAssignmentTask.finishedByRealName}</div>
						</div>
					</div>
					<div className="button-area">
						<button
							type="button"
							className="outline"
							onClick={() => popModal()}
							disabled={isLoading}
						>
							Close
						</button>
						<button
							type="button"
							className="default-red"
							onClick={() => saveChecklistTask(completedAtDate == null)}
							disabled={isLoading}
						>
							{completedAtDate != null ? "Remove Complete Task" : "Complete Task"}
						</button>
					</div>
				</div>
			)}
			{activeTab === "notes" && (
				<div className="notes">
					<div className="form-items">
						<label className="title" htmlFor="notes">
							Notes
						</label>
						<textarea
							name="notes"
							id="notes"
							value={formNotes}
							onChange={(e) => setFormNotes(e.target.value)}
						></textarea>
					</div>
					<div className="button-area">
						<button
							type="button"
							className="outline"
							onClick={() => popModal()}
							disabled={isLoading}
						>
							Close
						</button>
						<button
							type="button"
							className="default-red"
							onClick={() => saveChecklistTask()}
							disabled={isLoading}
						>
							Save
						</button>
						<button
							type="button"
							className="default-red"
							onClick={() => saveChecklistTask(completedAtDate == null)}
							disabled={isLoading}
						>
							Save & {completedAtDate != null ? "Remove Complete Task" : "Complete Task"}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};
