import React, { useCallback, useContext } from "react";
import { ChecklistTaskRow } from "../checklistReportDetail.service";
import { UserContext, UserContextType } from "../../../../../contexts/UserContext";
import { useSetRecoilState } from "recoil";
import { modalRenderAtom, modalTitleAtom } from "../../../../../contexts/modal.recoil";
import { ChecklistReportTaskModal } from "../CheckListReportTasks/cheklistReportTaskModal/ChecklistReportTaskModal";
import { formatDueDate } from "../../../checklistDetail/checklistTasksTable/ChecklistTasksTable.service";
import ParseHTMLContent from "../../../../../components/ParseHTMLContent/ParseHTMLContent";

type CheckListReportItemProps = {
	items: ChecklistTaskRow[];
	goLiveDate: Date;
	checklistName: string;
};
const ChecklistReportDetailTasksMobile: React.FC<CheckListReportItemProps> = ({
	items,
	goLiveDate,
	checklistName,
}) => {
	const { store } = useContext<UserContextType>(UserContext);
	const { translation } = store;

	const setModalRender = useSetRecoilState(modalRenderAtom);
	const setModalTitle = useSetRecoilState(modalTitleAtom);

	const openTaskDetailsModal = useCallback(
		(item: ChecklistTaskRow) => {
			setModalTitle("Task Details");
			setModalRender(
				<ChecklistReportTaskModal
					additionalResourcesUrl={item.moreDetailsUrl}
					formatDueDate={formatDueDate(goLiveDate, item.taskDueDateDays)}
					parentChecklistName={checklistName}
					taskDescription={translation(item.taskDescription, item.taskDescriptionEs)}
					taskName={translation(item.taskTitle, item.taskTitleEs)}
					isCritical={item.taskIsCritical}
					isCompleted={item.incompleteTasks.length === 0}
				/>,
			);
		},
		[checklistName, goLiveDate, setModalRender, setModalTitle, translation],
	);

	return (
		<div className="mobile-list-container">
			{items.map((item) => (
				<div className="mobile-list-item">
					<div className="mobile-list-item-row">
						<div className="mobile-list-item-cell label">Task</div>
						<div
							className="mobile-list-item-cell value anc"
							onClick={() => openTaskDetailsModal(item)}
						>
							<ParseHTMLContent htmlString={translation(item.taskTitle, item.taskTitleEs)} />
						</div>
					</div>
					<div className="mobile-list-item-row">
						<div className="mobile-list-item-cell label">Due Date</div>
						<div className="mobile-list-item-cell value">
							<div className="checklist-report-due-date-container">
								<div className="checklist-report-circle-container">
									<div
										className="checklist-report-circle checklist-report-due-date-container-items"
										style={{
											backgroundColor: formatDueDate(
												goLiveDate,
												item.taskDueDateDays,
												item.incompleteTasks.length === 0,
											).color,
										}}
									></div>
								</div>

								<div className="checklist-report-due-date-container-items">
									{
										formatDueDate(
											goLiveDate,
											item.taskDueDateDays,
											item.incompleteTasks.length === 0,
										).message
									}
								</div>
							</div>
						</div>
					</div>
					<div className="mobile-list-item-row">
						<div className="mobile-list-item-cell label">Completed Task</div>
						<div className="mobile-list-item-cell value">
							<div className="checklist-report-completed-task-container">
								{item.completedTasks.map((task, index) => (
									<div key={index}>{task}</div>
								))}
							</div>
						</div>
					</div>
					<div className="mobile-list-item-row">
						<div className="mobile-list-item-cell label">Not Completed Task</div>
						<div className="mobile-list-item-cell value">
							<div className="checklist-report-uncompleted-task-container">
								{item.incompleteTasks.map((task, index) => (
									<div key={index}>{task}</div>
								))}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default ChecklistReportDetailTasksMobile;
