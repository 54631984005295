import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PathName } from "../../constants/PathName";
import { UserContext } from "../../contexts/UserContext";
import useProduct from "../../hooks/useProduct";
import useQueryParams from "../../hooks/useQueryParams";
import {
	calculate_top_margin,
	getResInitiative,
	getResultProduct,
	isSearchQuouted,
	setPageTitle,
	strip_tags,
	substr,
	validateSearch,
} from "../../utils/utils";
import { useHistory } from "react-router";
import Loading from "../../components/Loading/Loading";
import { gql, useLazyQuery } from "@apollo/client";
import ParseHTMLContent from "../../components/ParseHTMLContent/ParseHTMLContent";

const TKIT_SEARCH_QUERY_PI = gql`
	query getTkitSearchPI(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
		$initiativeSlug: [String]
	) {
		productInfos(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				initiativeSlug: $initiativeSlug
			}
			first: 500
		) {
			nodes {
				productInfoFields {
					url
					titleEs
					title
					sortOrder
					parent
					id
					content {
						section {
							... on PiSection {
								id
								productInfoSectionFields {
									content
									contentEs
									section: id
									parent
									title
									titleEs
								}
							}
						}
					}
				}
				initiatives {
					nodes {
						slug
					}
				}
			}
		}
	}
`;
const TKIT_SEARCH_QUERY_SECTIONS = gql`
	query getTkitSearchSections(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
		$initiativeSlug: [String]
		$tkitSearch: [String]
		$tkitSearchLanguage: String
	) {
		piSections(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				initiativeSlug: $initiativeSlug
				tkitSearch: $tkitSearch
				tkitSearchLanguage: $tkitSearchLanguage
			}
			first: 1000
		) {
			nodes {
				id
				productInfoSectionFields {
					titleEs
					title
					parent
					secId: id
					contentEs
					fieldGroupName
					content
				}
			}
		}
	}
`;
const TKIT_SEARCH_QUERY_LAYGO = gql`
	query getTkitSearchLaygo(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
		$initiativeSlug: [String]
		$tkitSearch: [String]
		$tkitSearchLanguage: String
	) {
		laygoQuestions(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				initiativeSlug: $initiativeSlug
				tkitSearch: $tkitSearch
				tkitSearchLanguage: $tkitSearchLanguage
			}
			first: 1000
		) {
			nodes {
				learnAsYouGoQuestionsFields {
					url
					titleEs
					title
					tags
					product
					parent
					key
					id
					featured
					descriptionEs
					description
					contentEs
					content
					class
				}
				initiatives {
					nodes {
						slug
					}
				}
			}
		}
	}
`;
const TKIT_SEARCH_QUERY_MIX = gql`
	query getTkitSearchMix(
		$acceptAccessRole: String
		$acceptCompanyType: String
		$acceptCustomRole: [String]
		$userEid: String
		$initiativeSlug: [String]
		$tkitSearch: [String]
		$tkitSearchLanguage: String
	) {
		resourcesLibraries(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				initiativeSlug: $initiativeSlug
				tkitSearch: $tkitSearch
				tkitSearchLanguage: $tkitSearchLanguage
			}
			first: 1000
		) {
			nodes {
				id
				resourceLibraryFields {
					id
					initiative
					language
					url
					titleEs
					title
					content
					contentEs
					category
					duration
					excerptEs
					excerpt
					product
				}
				initiatives {
					nodes {
						slug
					}
				}
			}
		}
		whatsNews(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				initiativeSlug: $initiativeSlug
				tkitSearch: $tkitSearch
				tkitSearchLanguage: $tkitSearchLanguage
			}
			first: 1000
		) {
			nodes {
				whatsNewField {
					url
					titleEs
					title
					subtitleEs
					subtitle
					product
					initiative
					id
					fieldGroupName
					contentEs
					content
					app
				}
				initiatives {
					nodes {
						slug
					}
				}
			}
		}
		intros(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				initiativeSlug: $initiativeSlug
				tkitSearch: $tkitSearch
				tkitSearchLanguage: $tkitSearchLanguage
			}
			first: 1000
		) {
			nodes {
				introFields {
					url
					titleEs
					title
					product
					parent
					initiative
					id
					fieldGroupName
					contentEs
					content
				}
				initiatives {
					nodes {
						slug
					}
				}
			}
		}
		dashboardAccesses(
			where: {
				acceptAccessRole: $acceptAccessRole
				acceptCompanyType: $acceptCompanyType
				acceptCustomRole: $acceptCustomRole
				userEid: $userEid
				initiativeSlug: $initiativeSlug
				tkitSearch: $tkitSearch
				tkitSearchLanguage: $tkitSearchLanguage
			}
			first: 1000
		) {
			nodes {
				dashboardAccessLevelFields {
					url
					titleEs
					title
					product
					initiative
					id
					fieldGroupName
					content
					contentEs
				}
				initiatives {
					nodes {
						slug
					}
				}
			}
		}
	}
`;

type TkitSearch = {
	title: String;
	url: String;
	initiative: String;
	product: String;
	description: String;
	score: Number;
};

const PAGE_SIZE = 10;

const Search = () => {
	const query = useQueryParams();
	const { store }: any = useContext(UserContext);
	const { translation, userLanguage, permissionGroup, userData } = store;
	const product = useProduct;
	const history = useHistory();
	const [searchValue, setSearchValue] = useState("");
	const [isSearchValid, setSearchValid] = useState(true);
	const [currentpage, setCurrentPage] = useState(1);

	const searchTerm = query.get("q");

	const module = query.get("m") !== "null" ? query.get("m") : null;
	const msub = query.get("msub");

	const [getSearchPi, getSearchPiRes] = useLazyQuery(TKIT_SEARCH_QUERY_PI);
	const [getSearchSections, getSearchSectionsRes] = useLazyQuery(TKIT_SEARCH_QUERY_SECTIONS);
	const [getSearchLaygo, getSearchLaygoRes] = useLazyQuery(TKIT_SEARCH_QUERY_LAYGO);
	const [getSearchMix, getSearchMixRes] = useLazyQuery(TKIT_SEARCH_QUERY_MIX);

	const loading = () => {
		return (
			getSearchPiRes.loading ||
			getSearchLaygoRes.loading ||
			getSearchSectionsRes.loading ||
			getSearchMixRes.loading
		);
	};

	const error = () => {
		return (
			getSearchPiRes.error ||
			getSearchLaygoRes.error ||
			getSearchSectionsRes.error ||
			getSearchMixRes.error
		);
	};

	// const { loading, error, data } = useQuery(TKIT_SEARCH_QUERY, {
	// 	variables: {
	// 		acceptAccessRole: permissionGroup.accessLevel,
	// 		acceptCompanyType: permissionGroup.companyType,
	// 		acceptCustomRole: permissionGroup.customRole,
	// 		initiativeSlug: null,
	// 		tkitSearch: searchTerm,
	// 		tkitSearchLanguage: userLanguage,
	// 	},
	// });

	const submitSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		if (!isSearchValid) {
			return false;
		}

		const isQuoted = isSearchQuouted(searchValue);
		let cleanTerm = searchValue;
		if (isQuoted) {
			cleanTerm = cleanTerm.replaceAll('"', "").replaceAll("'", "");
		}

		const querySearch = isQuoted ? "'" + cleanTerm + "'" : cleanTerm;

		history.push(
			PathName.DASHBOARD +
				"mcd-search/" +
				"?q=" +
				querySearch +
				"&m=" +
				module +
				"&msub=" +
				msub +
				"&l=" +
				userLanguage,
		);
	};

	const countMatch = (term: string, title: string, description: string) => {
		const titleCount = (title?.match(new RegExp(term, "g")) || []).length;
		const descriptionCount = (description?.match(new RegExp(term, "g")) || []).length;
		return titleCount + descriptionCount;
	};

	const processTkitResults = (data: any) => {
		const results: TkitSearch[] = [];

		// get pisection ids array
		let piSectionIds: any[] = [];
		if (data.piSections) {
			piSectionIds = data.piSections.nodes.map((section: { id: any }) => {
				return section.id;
			});
		}

		// loop data post types
		Object.keys(data).forEach((key) => {
			// special case, ignore
			if (key === "piSections") {
				return;
			}

			// filter product infos
			// let filteredProductInfos = [];
			// if (key === "productInfos") {
			// 	filteredProductInfos = data.productInfos.nodes.filter(
			// 		(productInfo: { productInfoFields: { content: any[] } }) => {
			// 			return productInfo.productInfoFields.content.some((content: { section: { id: any } }) =>
			// 				piSectionIds.includes(content.section.id),
			// 			);
			// 		},
			// 	);
			// }

			/* figure out which array value to pull for each language */
			let title = "title";
			let content = "content";
			let description = "description";
			let tags = "tags";
			if (userLanguage === "es") {
				title = "title_es";
				content = "content_es";
				description = "description_es";
				tags = "tags_es";
			}

			// Loop through post types and push to results array
			data[key].nodes.forEach((node: { [x: string]: any }) => {
				const nodeKey = Object.keys(node).find((el) => el.includes("Field"));
				if (!nodeKey) {
					return;
				}

				const fieldsItem: any = node[nodeKey];

				if (
					fieldsItem["content"] &&
					Array.isArray(fieldsItem["content"]) &&
					fieldsItem["content"].length > 0
				) {
					fieldsItem["content"].forEach((subNodes) => {
						// @TODO very specific to product info and pi sections
						const subFieldsKey = Object.keys(subNodes.section).find((el) => el.includes("Field"));
						if (!subFieldsKey) {
							return;
						}
						if (!piSectionIds.includes(subNodes.section.id)) {
							return;
						}

						const subFieldsItem: any = subNodes.section[subFieldsKey];

						let resultTitle = "";
						let resultUrl = "";
						let resultInitiative = "";
						let resultProduct = "";
						let resultDescription = "";
						let resultScore = 0;

						// title: String;
						/* IF SUB-ARRAY TITLE IS EMPTY, USE PARENT ARRAY TITLE **/
						if (subFieldsItem[title] === "" || subFieldsItem[title] === null) {
							resultTitle = strip_tags(fieldsItem[title]);
						} else {
							/* IF SUB-ARRAY TITLE IS SET, THEN USE IT */
							resultTitle = strip_tags(subFieldsItem[title]);
							//$result_title = $result_title;
						}

						// url: String;
						/** IF SUB-ARRAY HAS AND ID, USE IT TO BUILD THE URL ANCHOR */
						if (subFieldsItem["section"] !== "") {
							if (
								subFieldsItem["product"] === "mcd-whats-new" ||
								subFieldsItem["product"] === "mcd-transitionkit"
							) {
								resultUrl =
									PathName.DASHBOARD +
									subFieldsItem["url"] +
									"&l=" +
									userLanguage +
									"#" +
									subFieldsItem["section"];
							} else {
								resultUrl =
									PathName.DASHBOARD +
									fieldsItem["product"] +
									"/" +
									fieldsItem["url"] +
									"&l=" +
									userLanguage +
									"#" +
									subFieldsItem["section"];
							}
						} else {
							/** OTHERWISE USE THE PARENT'S ID TO BUILD THE URL */
							if (
								fieldsItem["product"] === "mcd-whats-new" ||
								fieldsItem["product"] === "mcd-transitionkit" ||
								fieldsItem["product"] === ""
							) {
								resultUrl =
									PathName.DASHBOARD +
									fieldsItem["url"] +
									"&l=" +
									userLanguage +
									"#" +
									fieldsItem["id"];
							} else {
								resultUrl =
									PathName.DASHBOARD +
									fieldsItem["product"] +
									"/" +
									fieldsItem["url"] +
									"&l=" +
									userLanguage +
									"#" +
									fieldsItem["id"];
							}
						}

						// initiative: String;
						if (node["initiatives"] && node["initiatives"].nodes && node["initiatives"].nodes[0]) {
							resultInitiative = getResInitiative(node["initiatives"].nodes[0].slug);
						} else {
							resultInitiative = getResInitiative(fieldsItem["initiative"]);
						}

						// product: String;
						if (fieldsItem["product"]) {
							resultProduct = getResultProduct(fieldsItem["product"], userLanguage);
						}

						// description: String;
						if (subFieldsItem[content]) {
							resultDescription = strip_tags(subFieldsItem[content]);
						} else if (subFieldsItem[description]) {
							resultDescription = strip_tags(subFieldsItem[description]);
						} else if (subFieldsItem[tags]) {
							resultDescription = strip_tags(subFieldsItem[tags]);
						}

						// score: Number;
						resultScore = countMatch(searchTerm!, resultTitle, resultDescription);

						const resObj = {
							title: resultTitle,
							url: resultUrl,
							initiative: resultInitiative,
							product: resultProduct,
							description: resultDescription,
							score: resultScore,
						};

						if (resultTitle && resultTitle !== "") {
							results.push(resObj);
						}
					});
				} else {
					// Get results on parent
					let resultTitle = "";
					let resultUrl = "";
					let resultInitiative = "";
					let resultProduct = "";
					let resultDescription = "";
					let resultScore = 0;

					/* GET TITLE */
					resultTitle = strip_tags(fieldsItem[title]);

					/** BIULD URL */
					if (
						fieldsItem["product"] === "mcd-whats-new" ||
						fieldsItem["product"] === "whats-new" ||
						fieldsItem["product"] === "mcd-transitionkit" ||
						fieldsItem["product"] === "" ||
						fieldsItem["product"] === null ||
						fieldsItem["product"] === undefined
					) {
						if (fieldsItem["url"] === null) {
							resultUrl =
								PathName.DASHBOARD +
								"?p=whats-new" +
								"&t=" +
								(fieldsItem["initiative"] ? fieldsItem["initiative"].toLowerCase() : "") +
								"&l=" +
								userLanguage +
								"#" +
								fieldsItem["id"];
						} else {
							resultUrl =
								PathName.DASHBOARD +
								fieldsItem["url"] +
								"&l=" +
								userLanguage +
								"#" +
								fieldsItem["id"];
						}
					} else {
						resultUrl =
							PathName.DASHBOARD +
							fieldsItem["product"] +
							"/" +
							fieldsItem["url"] +
							"&l=" +
							userLanguage +
							"#" +
							fieldsItem["id"];
					}

					/* GET INITIATIVE */
					if (node["initiatives"] && node["initiatives"].nodes && node["initiatives"].nodes[0]) {
						resultInitiative = getResInitiative(node["initiatives"].nodes[0].slug);
					} else {
						resultInitiative = getResInitiative(fieldsItem["initiative"]);
					}

					/* GET RESULT PRODUCT */
					if (fieldsItem["product"]) {
						resultProduct = getResultProduct(fieldsItem["product"], userLanguage);
					}

					/* GET DESCRIPTION */
					if (fieldsItem[content]) {
						resultDescription = strip_tags(fieldsItem[content]);
					} else if (fieldsItem[description]) {
						resultDescription = strip_tags(fieldsItem[description]);
					} else if (fieldsItem[tags]) {
						resultDescription = strip_tags(fieldsItem[tags]);
					}

					// score: Number;
					resultScore = countMatch(searchTerm!, resultTitle, resultDescription);

					const resObj = {
						title: resultTitle,
						url: resultUrl,
						initiative: resultInitiative,
						product: resultProduct,
						description: resultDescription,
						score: resultScore,
					};

					if (resultTitle && resultTitle !== "") {
						results.push(resObj);
					}
				}
			});
		});
		return results;
	};

	useEffect(() => {
		setPageTitle(product);
	}, []);

	useEffect(() => {
		if (searchTerm !== null && searchTerm !== undefined) {
			const isQuoted = isSearchQuouted(searchTerm);
			let cleanTerm = searchTerm;
			if (isQuoted) {
				cleanTerm = cleanTerm.replaceAll('"', "").replaceAll("'", "");
			}

			const termArray = isQuoted ? [cleanTerm] : cleanTerm.split(/\s+/);

			const isInvalid = termArray.some((term) => {
				return !validateSearch(term, userLanguage);
			});

			if (!isInvalid) {
				getSearchPi({
					variables: {
						acceptAccessRole: permissionGroup.accessLevel,
						acceptCompanyType: permissionGroup.companyType,
						acceptCustomRole: permissionGroup.customRole,
						userEid: userData.eID,
						initiativeSlug: module || null,
					},
				});
				getSearchSections({
					variables: {
						acceptAccessRole: permissionGroup.accessLevel,
						acceptCompanyType: permissionGroup.companyType,
						acceptCustomRole: permissionGroup.customRole,
						userEid: userData.eID,
						initiativeSlug: module || null,
						tkitSearch: termArray,
						tkitSearchLanguage: userLanguage,
					},
				});
				getSearchLaygo({
					variables: {
						acceptAccessRole: permissionGroup.accessLevel,
						acceptCompanyType: permissionGroup.companyType,
						acceptCustomRole: permissionGroup.customRole,
						userEid: userData.eID,
						initiativeSlug: module || null,
						tkitSearch: termArray,
						tkitSearchLanguage: userLanguage,
					},
				});
				getSearchMix({
					variables: {
						acceptAccessRole: permissionGroup.accessLevel,
						acceptCompanyType: permissionGroup.companyType,
						acceptCustomRole: permissionGroup.customRole,
						userEid: userData.eID,
						initiativeSlug: module || null,
						tkitSearch: termArray,
						tkitSearchLanguage: userLanguage,
					},
				});
				setSearchValid(true);
			} else {
				setSearchValid(false);
			}
		}
	}, [searchTerm]);

	useEffect(() => {
		if (searchValue !== null && searchValue !== undefined) {
			let cleanTerm = searchValue;
			cleanTerm = cleanTerm.replaceAll('"', "").replaceAll("'", "");
			const termArray = cleanTerm.split(/\s+/);
			const isInvalid = termArray.some((term) => {
				return !validateSearch(term, userLanguage);
			});

			if (!isInvalid) {
				setSearchValid(true);
			} else {
				setSearchValid(false);
			}
		}
	}, [searchValue]);

	if (loading()) return <Loading />;
	if (error()) return <p style={{ ...calculate_top_margin() }}>Unable to load Search :(</p>;

	let searchResults: TkitSearch[] = [];
	if (getSearchPiRes.data && getSearchSectionsRes.data) {
		searchResults.push(
			...processTkitResults({ ...getSearchPiRes.data, ...getSearchSectionsRes.data }),
		);
	}
	// if (getSearchSectionsRes.data) {
	// 	searchResults.push(...processTkitResults(getSearchSectionsRes.data));
	// }
	if (getSearchLaygoRes.data) {
		searchResults.push(...processTkitResults(getSearchLaygoRes.data));
	}
	if (getSearchMixRes.data) {
		searchResults.push(...processTkitResults(getSearchMixRes.data));
	}

	searchResults.sort((a, b) => Number(b.score) - Number(a.score));

	return (
		<div className="container-fluid main" style={{ ...calculate_top_margin() }}>
			<div className="row">
				{!searchTerm && (
					<form id="#search_form" className="search-form" onSubmit={submitSearch}>
						<div className="col-xs-8">
							<input
								className={isSearchValid ? "" : "invalid"}
								type="text"
								name="q"
								id="search_input"
								value={searchValue}
								onInput={(e) => setSearchValue(e.currentTarget.value)}
								placeholder={translation("Search", "Buscar")}
							/>
						</div>
						<div className="col-xs-2">
							<button
								type="submit"
								name="submit"
								className="btn btn-primary search-button"
								value="submit"
							>
								{translation("Search", "Buscar")}
							</button>
						</div>
						<div className="col-xs-12">
							<p>
								<ParseHTMLContent
									htmlString={translation(
										"<strong>Tip:</strong> you can search for an exact phrase by enclosing it in single (') or double (\") quotes",
										"<strong>Consejo:</strong> puedes buscar una frase exacta encerrándola en comillas simples (') o dobles (\")",
									)}
								/>
							</p>
						</div>
					</form>
				)}
				{searchTerm && (
					<>
						<div className="col-xs-12">
							<h1>{translation("Search Results", "Resultados de B&uacute;squeda")}</h1>
							<p>
								{searchResults.length}{" "}
								{translation("Results found for", "Resultados encontrados para")}{" "}
								<em>{searchTerm}</em>
							</p>
							{searchResults.length > PAGE_SIZE && (
								<div className="pagination-info">
									Showing: {(currentpage - 1) * PAGE_SIZE + 1} - {currentpage * PAGE_SIZE}
								</div>
							)}
						</div>
						<div className="col-xs-12 last">
							{searchResults
								.slice((currentpage - 1) * PAGE_SIZE, currentpage * PAGE_SIZE)
								.map((results, i) => {
									/* truncate description to 500 characters */
									const resultsDescription = substr(results["description"], 0, 500);
									return (
										<React.Fragment key={i}>
											<h3>
												<small>{results["initiative"] + " > " + results["product"] + " > "}</small>
												<br />
												<Link to={results["url"] + ""} target="_blank">
													{results["title"]}
												</Link>
											</h3>
											<p>
												<ParseHTMLContent htmlString={resultsDescription + "..."} />
											</p>
											<hr />
										</React.Fragment>
									);
								})}

							{/** If nothing is found **/}
							{searchResults.length === 0 && (
								<p>
									{translation(
										"Sorry, coudn't find anything. Please try again.",
										"Lo siento, no encontramos nada. Por favor intenta de nuevo.",
									)}
								</p>
							)}
						</div>
						{searchResults.length > PAGE_SIZE && (
							<div className="col-xs-12 ">
								<div className="pagination-info">
									Showing: {(currentpage - 1) * PAGE_SIZE + 1} - {currentpage * PAGE_SIZE}
								</div>
								<div className="pagination-container">
									{[...Array(Math.ceil(searchResults.length / PAGE_SIZE))].map((v, i) => {
										return (
											<div
												key={"pag-" + i}
												className={"pagination-item " + (currentpage === i + 1 ? "active" : "")}
												onClick={() => setCurrentPage(i + 1)}
											>
												{i + 1}
											</div>
										);
									})}
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Search;
