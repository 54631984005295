import { Redirect, Route, Switch } from "react-router";
import ChecklistDetail from "../../apps/checklists/checklistDetail/ChecklistDetail";
import ChecklistReportDetail from "../../apps/checklists/checklistReports/checklistReportDetail/ChecklistReportDetail";
import ChecklistReports from "../../apps/checklists/checklistReports/ChecklistReports";
import { CheckLists } from "../../apps/checklists/Checklists";

import FlashCards from "../../apps/flashcards/Flashcards";
import Info from "../../apps/info/Info";
import LAYGo from "../../apps/laygo/LAYGo";
import Paths from "../../apps/paths/Paths";
import Resources from "../../apps/resources/Resources";
import Search from "../../apps/search/Search";
import TransitionKit from "../../apps/transitionkit/TransitionKit";
import { PathName } from "../../constants/PathName";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Navbar from "../Navbar/Navbar";
import TopPdfViewer from "../TopPdfViewer/TopPdfViewer";
import { Forms } from "../../apps/forms/Forms";

const NavLayout: React.FC = () => {
	return (
		<div id="nav-layout">
			<Navbar />
			<Breadcrumbs />
			<TopPdfViewer />
			<Switch>
				<Route exact path={PathName.DASHBOARD} render={() => <TransitionKit />} />
				<Route exact path={PathName.FLASHCARDS} render={() => <FlashCards />} />
				<Route exact path={PathName.INFO} render={() => <Info />} />
				<Route exact path={PathName.LAYGO} render={() => <LAYGo />} />
				<Route exact path={PathName.PATHS} render={() => <Paths />} />
				<Route exact path={PathName.RESOURCES} render={() => <Resources />} />
				<Route exact path={PathName.SEARCH} render={() => <Search />} />
				<Route exact path={PathName.CHECKLISTS} render={() => <CheckLists />} />
				<Route exact path={PathName.FORMS} render={() => <Forms />} />

				<Route exact path={PathName.CHECKLIST_REPORTS} render={() => <ChecklistReports />} />
				<Route
					exact
					path={PathName.CHECKLIST(":checklistAssignmentId")}
					render={() => <ChecklistDetail />}
				/>
				<Route
					exact
					path={PathName.CHECKLIST_REPORT(":checklistAssignmentId")}
					render={() => <ChecklistReportDetail />}
				/>
				<Route render={() => <Redirect to={"/404"} />} />
			</Switch>
		</div>
	);
};

export default NavLayout;
