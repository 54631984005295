import React, { useCallback, useContext } from "react";
import { ChecklistAssignmentTaskTableRowFragment } from "../../../../../generated/graphql";
import { FaCalendarAlt, FaStickyNote } from "react-icons/fa";
import { MdCheckCircle } from "react-icons/md";
import { UserContext, UserContextType } from "../../../../../contexts/UserContext";
import { formatDueDate } from "../ChecklistTasksTable.service";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { GiCircle } from "react-icons/gi";
import {
	formatDateFromYYYYMMDD,
	formatDateTimeYYYY_MM_DD_HH_MM_SS,
	formatDate_MM_DD_YYYY,
} from "../../../../../utils/utils-typescript";
import { ChecklistTaskModal } from "../checklistTaskModal/ChecklistTaskModal";
import { useWindowBreakpoints } from "../../../../../hooks/useWindowBreakpoints";
import {
	useFetchChecklistAssignmentTasks,
	useGetChecklistAssignment,
	useUpsertChecklistAssignmentTask,
} from "../../checklistDetail.hooks";
import Loading from "../../../../../components/Loading/Loading";
import ParseHTMLContent from "../../../../../components/ParseHTMLContent/ParseHTMLContent";
import { useModals } from "../../../../../components/modal/modals.recoil";

interface Props {
	item: ChecklistAssignmentTaskTableRowFragment;
	checklistAssignmentId: number;
	checklistAssignmentDate: Date | null;
}

const ChecklistTaskRow: React.FC<Props> = ({
	item,
	checklistAssignmentId,
	checklistAssignmentDate,
}) => {
	const { store } = useContext<UserContextType>(UserContext);
	const { translation, personID } = store;
	const { isWindowMobile } = useWindowBreakpoints();

	const { checklistAssignment } = useGetChecklistAssignment();

	const { pushModal } = useModals();
	const { fetchChecklistAssignmentTasks, isLoading } = useFetchChecklistAssignmentTasks();

	// MUTATIONS
	const { upsertChecklistAssignmentTask, isLoading: ucatLoading } =
		useUpsertChecklistAssignmentTask();

	const isAdding = ucatLoading || isLoading;
	const isRemoving = ucatLoading || isLoading;

	const isComplete = item.finishedAt != null;

	const renderChecklistTaskModal = useCallback(() => {
		pushModal({
			render: <ChecklistTaskModal checklistAssignmentTask={item} date={checklistAssignmentDate} />,
		});
	}, [checklistAssignmentDate, item, pushModal]);

	const completedAtDate = item.finishedAt;
	const isShared = checklistAssignment?.sharedType === "Shared";
	return (
		<tr className="checklist-row">
			{isLoading && <Loading />}
			<td className="col-task">
				<div className="checklist-table-element">
					{isComplete ? (
						<>
							{isRemoving ? (
								<AiOutlineLoading3Quarters className="checklist-check-icon spin gray" />
							) : (
								<MdCheckCircle
									className="checklist-check-icon yellow"
									onClick={async () => {
										try {
											// remove finished at/by
											await upsertChecklistAssignmentTask({
												id: item.checklistAssignmentTaskId,
												taskId: parseInt(item.taskId!),
												finishedAt: null,
												finishedBy: null,
												notes: item.notes !== "" ? item.notes : null,
											});

											await fetchChecklistAssignmentTasks();
										} catch (error) {
											console.error(error);
										}
									}}
								/>
							)}
						</>
					) : (
						<>
							{isAdding ? (
								<AiOutlineLoading3Quarters className="checklist-check-icon spin yellow" />
							) : (
								<GiCircle
									className="checklist-check-icon gray"
									onClick={async () => {
										try {
											if (item?.taskId == null || parseInt(item.taskId) <= 0) {
												console.error("missing task id:", item.taskId);
												return;
											}
											await upsertChecklistAssignmentTask({
												id: item.checklistAssignmentTaskId,
												taskId: parseInt(item.taskId),
												finishedAt: formatDateTimeYYYY_MM_DD_HH_MM_SS(new Date()),
												finishedBy: personID,
												notes: item.notes !== "" ? item.notes : null,
											});
											fetchChecklistAssignmentTasks();
										} catch (error) {
											console.error(error);
										}
									}}
								/>
							)}
						</>
					)}
					<label
						className={"task-label " + (item.taskIsCritical ? " critical " : "")}
						htmlFor={`checkbox-${item.checklistAssignmentTaskId}`}
					>
						<div className="anc" onClick={() => renderChecklistTaskModal()}>
							<ParseHTMLContent htmlString={translation(item.taskTitle, item.taskTitleEs)} />{" "}
							{item.taskType === "event" && <FaCalendarAlt />}
							{item.notes != null && <FaStickyNote />}
						</div>
					</label>
				</div>
			</td>
			{checklistAssignmentDate != null && (
				<td className="col-due-date">
					{(item.taskDaysOrActualDate === "Due Date Days" ||
						item.taskDaysOrActualDate === "due_date_days") &&
						item.taskDueDateDays != null && (
							<div className="checklist-table-element checklist-due-date-container">
								<div className="checklist-circle-container">
									<div
										className="checklist-circle checklist-due-date-container-items"
										style={{
											backgroundColor: formatDueDate(
												checklistAssignmentDate,
												item.taskDueDateDays,
												completedAtDate != null,
											).color,
										}}
									></div>
								</div>

								<div className="checklist-due-date-container-items">
									{item.taskDueDateDays != null &&
										formatDueDate(
											checklistAssignmentDate,
											item.taskDueDateDays,
											completedAtDate != null,
										).message}{" "}
								</div>
							</div>
						)}
					{item.taskDaysOrActualDate === "actual_due_date" && item.taskActualDueDate != null && (
						<div className="checklist-table-element checklist-actual-due-date-container">
							{formatDateFromYYYYMMDD(item.taskActualDueDate).dateString}{" "}
							{item.taskActualDueDateTime}{" "}
							{item.taskActualDueDateTime != null && `(${item.taskActualDueDateTimezone})`}
						</div>
					)}
				</td>
			)}
			{!isWindowMobile && (
				<td className="col-completed-date">
					{completedAtDate != null ? formatDate_MM_DD_YYYY(new Date(completedAtDate)) : ""}
				</td>
			)}
			{!isWindowMobile && isShared && (
				<td className="col-completed-by">{item.finishedByRealName}</td>
			)}
		</tr>
	);
};

export default ChecklistTaskRow;
