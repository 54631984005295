import React from "react";
import UserTranslationService from "../services/i18n";

export interface UserContextType {
	store: UserState;
}

export interface UserState {
	personID: string;
	userLanguage: string;
	translation: (english: any, spanish: any) => any;
	realName: string;
	permissionGroup: {
		accessLevel: string;
		companyType: string;
		customRole?: string[];
	};
	userSettings: {
		support: string;
		citLead: number;
		otp: number;
		oa: number;
		fieldOffice: string | null;
		systems: string | null;
	};
	//Other User DB fields, needed for updates
	userData: {
		dn: string;
		eID: string;
		userRole: string;
		companyName: string;
		email: string;
		phone: string;
		lastAccess: string;
		storeNumber: string;
	};
	isSuperUser: boolean;
}

const defaultLanguage = "en";
export const initialUserState = {
	personID: "",
	userLanguage: defaultLanguage,
	translation: UserTranslationService(defaultLanguage).userTranslation,
	realName: "",
	permissionGroup: {
		accessLevel: "",
		companyType: "",
		customRole: undefined,
	},
	userSettings: {
		support: "",
		citLead: 0,
		otp: 0,
		oa: 0,
		fieldOffice: null,
		systems: null,
	},
	//Other User DB fields, needed for updates
	userData: {
		dn: "",
		eID: "",
		userRole: "",
		companyName: "",
		email: "",
		phone: "",
		lastAccess: "",
		storeNumber: "",
	},
	isSuperUser: false,
};

export const UserContext = React.createContext<UserContextType>({ store: initialUserState });

export const userReducer = (state: UserState, action: any) => {
	switch (action.type) {
		case "reset":
			return initialUserState;
		case "setUserLanguage": {
			const data = action.data;
			const newState = {
				userLanguage: data.userLanguage,
				translation: UserTranslationService(data.userLanguage).userTranslation,
			};
			return { ...state, ...newState };
		}
		case "setPermissionGroup": {
			const newState = {
				permissionGroup: {
					...action.data,
				},
			};
			return { ...state, ...newState };
		}
		case "setUserSettings": {
			const newState = {
				userSettings: {
					...action.data,
				},
			};
			return { ...state, ...newState };
		}
		case "setUserData": {
			const newState = {
				userData: {
					...action.data,
				},
			};
			return { ...state, ...newState };
		}
		case "setPersonID": {
			const personID = action.data;
			return { ...state, personID };
		}
		case "setRealName": {
			const realName = action.data;
			return { ...state, realName };
		}
		case "setIsSuperUser": {
			const isSuperUser = action.data;
			return { ...state, isSuperUser };
		}
		default:
			return state;
	}
};
