import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../../contexts/UserContext";
import useQueryParams from "../../../../hooks/useQueryParams";
import { in_array, isset, preg_match } from "../../../../utils/utils";

const UPDATE_USER_SETTINGS_MUTATION = gql`
	mutation updateUserAdditionalRoles(
		$personID: String!
		$cit_lead: Int!
		$oa: Int!
		$otp: Int!
		$support: String!
	) {
		updateTKITUserSettings(
			input: {
				tKITUserSettingsFieldsInput: {
					personID: $personID
					cit_lead: $cit_lead
					oa: $oa
					otp: $otp
					support: $support
				}
			}
		) {
			message
			didUpdate
		}
	}
`;

const UPDATE_USER_MUTATION = gql`
	mutation updateUser(
		$company_name: String!
		$company_type: String!
		$dn: String!
		$eID: String!
		$email: String!
		$last_access: String!
		$personID: String!
		$phone: String!
		$real_name: String!
		$store_number: String!
		$user_role: String!
		$access_level: String!
	) {
		updateTKITUser(
			input: {
				tKITUserFieldsInput: {
					company_name: $company_name
					company_type: $company_type
					dn: $dn
					eID: $eID
					email: $email
					last_access: $last_access
					personID: $personID
					phone: $phone
					real_name: $real_name
					store_number: $store_number
					user_role: $user_role
					access_level: $access_level
				}
			}
		) {
			message
			didUpdate
		}
	}
`;

const RESET_USER_DB_MUTATION = gql`
	mutation resetUserDB($personID: String!) {
		resetTKITUserDB(input: { personID: $personID }) {
			didUpdate
			message
		}
	}
`;

const SuperSelect = () => {
	const query = useQueryParams();
	const { store, dispatch } = useContext(UserContext);
	const { translation, permissionGroup, personID, userSettings, userData, realName } = store;
	const qry = {
		m: query.get("m"),
		msub: query.get("msub"),
		s: query.get("s"),
		ss1: query.get("ss1"),
		p: query.get("p"),
		l: query.get("l"),
		cat: query.get("cat"),
		quiz: query.get("quiz"),
		view: query.get("view"),
		sort: query.get("sort"),
	};
	const location = useLocation();
	const hideAdditionalRoles = ["Level 5", "Level 6", "None", "Organization Admin", "Operator"];

	const [updateUserAdditionalRoles, { loading: UpdateRolesLoading }] = useMutation(
		UPDATE_USER_SETTINGS_MUTATION,
	);
	const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER_MUTATION);
	const [resetTKITUserDB, { loading: resetDBLoading }] = useMutation(RESET_USER_DB_MUTATION);

	const mutationsLoading = () => {
		return UpdateRolesLoading || updateUserLoading || resetDBLoading;
	};

	const userFieldsInput = {
		personID: personID,
		real_name: realName,
		company_type: permissionGroup.companyType,
		access_level: permissionGroup.accessLevel,

		company_name: userData.companyName,
		dn: userData.dn,
		eID: userData.eID,
		email: userData.email,
		last_access: userData.lastAccess,
		phone: userData.phone,
		store_number: userData.storeNumber,
		user_role: userData.userRole,
	};

	const citTeamIDs = [
		/* these are all personID's that get the reset database link in production */
		"163134", //Kathy Lopez
		"165398", //Bridget Weber
		"306657", //Greg Balakian
		"153380", //Regina Adams
		"1762319", //Jamie Prendergast
		/* these are the test IDs for staging */
		"SIMez712329",
		"SIMez712330",
		"SIMez712333", //stg user
		//@TODO REMOVE THIS< TESTING PURPOSE ONLY
		"2317651",
	];

	const $SHOW_ERRORS = false;

	//functions
	function get_companyType_title(role) {
		let companyType_title = "";
		switch (role) {
			case "franchisee":
				companyType_title = "Franchisee";
				break;
			case "mcopco":
				companyType_title = "McOpCo";
				break;
			default:
		}
		return <b>{companyType_title},</b>;
	}

	function get_accessLevel_title(level) {
		let accessLevelTitle = "";
		switch (level) {
			case "None":
				accessLevelTitle = "McD Staff";
				break;
			case "Level 1":
				accessLevelTitle = "General Manager";
				break;
			case "Level 2":
				accessLevelTitle = "Kitchen Manager";
				break;
			case "Level 3":
				accessLevelTitle = "People Manager";
				break;
			case "Level 4":
				accessLevelTitle = "Guest Services Manager";
				break;
			case "Level 5":
				accessLevelTitle = "Shift Manager";
				break;
			case "Level 6":
				accessLevelTitle = "Manager Trainee";
				break;
			default:
				accessLevelTitle = level;
		}
		return <b>{accessLevelTitle}</b>;
	}

	function get_customRole_title(role) {
		let customRoleTitle = "";
		switch (role) {
			case "Both":
				customRoleTitle = "CIT & Tech Lead";
				break;
			case "OTP":
				customRoleTitle = "Tech Lead";
				break;
			case "CIT Lead":
				customRoleTitle = "CIT Lead";
				break;
			default:
				customRoleTitle = "None";
		}
		return (
			<span>
				, and <b>{customRoleTitle}</b>
			</span>
		);
	}

	const updateCompanyType = async (value) => {
		await Promise.all([
			updateUser({
				variables: {
					...userFieldsInput,
					personID,
					company_type: value,
				},
			}),
			updateUserAdditionalRoles({
				variables: {
					personID,
					oa: userSettings.oa,
					support: value,
					cit_lead: userSettings.citLead,
					otp: userSettings.otp,
				},
			}),
		]);

		//Update Context Values
		dispatch({
			type: "setUserSettings",
			data: {
				...userSettings,
				support: value,
			},
		});
		dispatch({
			type: "setPermissionGroup",
			data: {
				...permissionGroup,
				companyType: value,
			},
		});
	};

	const updateAccessLevel = (value) => {
		updateUser({
			variables: {
				...userFieldsInput,
				personID,
				access_level: value,
			},
		}).then(() => {
			//update context
			dispatch({
				type: "setPermissionGroup",
				data: {
					...permissionGroup,
					accessLevel: value,
				},
			});
		});
	};

	const updateCustomRoles = (value) => {
		//has OA?
		const newCustomRoles = permissionGroup.customRole?.includes("OA") ? ["OA"] : [];
		let newCITLead = userSettings.citLead;
		let newOTP = userSettings.otp;
		switch (value) {
			case "CIT Lead":
				newCustomRoles.push("CIT Lead");
				newCITLead = 1;
				newOTP = 0;
				break;
			case "OTP":
				newCustomRoles.push("OTP");
				newCITLead = 0;
				newOTP = 1;
				break;
			case "Both":
				newCustomRoles.push("CIT Lead", "OTP");
				newCITLead = 1;
				newOTP = 1;
				break;
			default:
				//None
				newCustomRoles.push("None");
				newCITLead = 0;
				newOTP = 0;
				break;
		}

		updateUserAdditionalRoles({
			variables: {
				personID,
				oa: userSettings.oa,
				support: userSettings.support,
				cit_lead: newCITLead,
				otp: newOTP,
			},
		}).then(() => {
			//Update Context
			dispatch({
				type: "setUserSettings",
				data: {
					...userSettings,
					citLead: newCITLead,
					otp: newOTP,
				},
			});
			dispatch({
				type: "setPermissionGroup",
				data: {
					...permissionGroup,
					customRole: newCustomRoles,
				},
			});
		});
	};

	const defaultCompanyType = () => {
		const realSupport = userSettings.support === "None" ? "franchisee" : userSettings.support;
		return realSupport || permissionGroup.companyType || "franchisee";
	};

	const superSelectCustomRole = () => {
		return userSettings.citLead === 1
			? userSettings.otp === 1
				? "Both"
				: "CIT Lead"
			: userSettings.otp === 1
			? "OTP"
			: "None";
	};

	const resetDB = () => {
		resetTKITUserDB({
			variables: {
				personID,
			},
		}).then(() => {
			//reload Page
			window.location.reload(false);
		});
	};

	return (
		<div id="superSelect">
			<div className="row">
				<div className="col-xs-12">
					<p id="minMaxSuperSelect" className="minimize">
						{translation("Currently viewing as ", "Viendo como: ")}
						{get_companyType_title(permissionGroup.companyType)}
						{permissionGroup.companyType === "mcopco" && <> and </>}
						{get_accessLevel_title(permissionGroup.accessLevel)}
						{permissionGroup.companyType === "franchisee" ? (
							<>{get_customRole_title(permissionGroup.customRole)}</>
						) : (
							<>.</>
						)}
					</p>
				</div>
				<div className="col-xs-12">
					<form
						id="superSelectForm"
						className=""
						name="superSelectForm"
						method="get"
						//@TODO verify if same effect
						// action={$_SERVER["REQUEST_URI"]}
						action={location.pathname + location.search}
					>
						{isset(qry.m) ? (
							<input type="hidden" name="m" value={qry.m} />
						) : (
							<input type="hidden" name="m" value="" />
						)}

						{isset(qry.msub) ? (
							<input type="hidden" name="msub" value={qry.msub} />
						) : (
							<input type="hidden" name="msub" value="" />
						)}

						{isset(qry.s) && <input type="hidden" name="s" value={qry.s} />}

						{isset(qry.ss1) && <input type="hidden" name="ss1" value={qry.ss1} />}
						{isset(qry.p) && <input type="hidden" name="p" value={qry.p} />}
						{isset(qry.l) && <input type="hidden" name="l" value={qry.l} />}
						{isset(qry.cat) && <input type="hidden" name="cat" value={qry.cat} />}
						{isset(qry.quiz) && <input type="hidden" name="quiz" value={qry.quiz} />}
						{isset(qry.view) && <input type="hidden" name="view" value={qry.view} />}
						{isset(qry.sort) && <input type="hidden" name="sort" value={qry.sort} />}
						{isset(qry.t) && <input type="hidden" name="t" value={qry.t} />}
						{isset(qry.q) && (
							<>
								{/* @TODO investigate what this $q was supposed to to */}
								{() => {
									if (preg_match('/"/', qry.q)) {
										/* remove quotes */
										// $q = str_replace('"', "'", qry.q);
									} else if (preg_match("/'/", qry.q)) {
										// $q = str_replace("'", "'", qry.q);
									}
									return <input type="hidden" name="q" value={qry.q} />;
								}}
							</>
						)}
						{$SHOW_ERRORS && (
							<div>
								sso_supportType is set to {userSettings.support} <br />
								sso_companyType is set to {permissionGroup.companyType} <br />
							</div>
						)}

						<ul>
							<li>
								<label htmlFor="superSelectCompanyType">
									{translation("Company Type:", "Tipo de Restaurante:")}
								</label>
								<select
									className={mutationsLoading() ? "disabled" : ""}
									name="superSelectCompanyType"
									id="superSelectCompanyType"
									onChange={(e) => updateCompanyType(e.target.value)}
									value={defaultCompanyType()}
								>
									<option value="franchisee">{translation("Franchisee", "Franquisia")}</option>
									<option value="mcopco">McOpCo</option>
								</select>
							</li>
							<li>
								<label htmlFor="superSelectAccessLevel">
									{translation("Access Level:", "Nivel de Acceso:")}
								</label>
								<select
									className={mutationsLoading() ? "disabled" : ""}
									name="superSelectAccessLevel"
									id="superSelectAccessLevel"
									onChange={(e) => updateAccessLevel(e.target.value)}
									value={permissionGroup.accessLevel}
								>
									<option value="None">{translation("McD Staff", "Personal de McD")}</option>
									<option value="Level 1">
										{translation("General Manager", "Gerente General")}
									</option>
									<option value="Level 2">
										{translation("Kitchen Manager", "Gerente de Cocina")}
									</option>
									<option value="Level 3">
										{translation("People Manager", "Gerente de Personal")}
									</option>
									<option value="Level 4">
										{translation("Guest Services Manager", "Gerente de Servicios al Cliente")}
									</option>
									<option value="Level 5">
										{translation("Shift Manager", "Gerente de Turno")}
									</option>
									<option value="Level 6">
										{translation("Manager Trainee", "Aprendíz de Gerente")}
									</option>

									{permissionGroup.companyType === "franchisee" && (
										<>
											<option value="Supervisor">
												{translation("Mid Manager", "Gerente Intermedio")}
											</option>
											<option value="Supervisor w/ Role Assignment">
												{translation("Supervisor w/ Role Assignment", "Supervisor con Función")}
											</option>
											<option value="Organization Admin">
												{translation("Organization Admin", "Administrador de Organización")}
											</option>
											<option value="Operator">
												{translation("Owner/Operator", "Propietario/Operador")}
											</option>
										</>
									)}
								</select>
							</li>
							{permissionGroup.companyType !== "mcopco" &&
								!in_array(permissionGroup.accessLevel, hideAdditionalRoles) && (
									<li>
										<label htmlFor="superSelectCustomRole">
											{translation("Additional Roles:", "Funciones Adicionales:")}
										</label>
										<select
											className={mutationsLoading() ? "disabled" : ""}
											name="superSelectCustomRole"
											id="superSelectCustomRole"
											onChange={(e) => updateCustomRoles(e.target.value)}
											value={superSelectCustomRole()}
										>
											<option value="None" default>
												{translation("None", "Ninguno")}
											</option>
											<option value="CIT Lead">{translation("CIT Lead", "Líder de CIT")}</option>
											<option value="OTP">
												{translation("Tech Lead", "Líder de Technología")}
											</option>
											<option value="Both">
												{translation("Both (CIT & Tech Lead)", "Ambos (CIT y Tecnología)")}
											</option>
										</select>
									</li>
								)}
							<li>
								{(in_array(personID, citTeamIDs) ||
									location.pathname === "https://mcdtkit.test/") && (
									<div
										className={"anc " + (mutationsLoading() ? "disabled" : "")}
										onClick={() => resetDB()}
									>
										{translation("Reset Databases", "Reiniciar Base de Datos")}
									</div>
								)}
							</li>
						</ul>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SuperSelect;
